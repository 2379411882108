import { FC, useCallback, useEffect, useState } from 'react';
import {
  AccountInterface,
  DestinationInterface,
  LocationInterface,
} from '../../interfaces';
import {
  getFavoriteAndHistoricalLocations,
  getFilteredAccounts,
} from '../../services';
import { formatName } from '../../utils/format';
import { FormGroupSearch } from '../FormFields/FormFile';
import { UserPlusIcon } from '@heroicons/react/20/solid';
import { useAppSelector } from '../../store/hooks';

interface AccountSearchProps {
  title: string;
  selected?: AccountInterface;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  hideCreate?: boolean;
  hideFavAndShip?: boolean;
  onChange?: (search: string) => void;
  onSelectClient: (option: AccountInterface) => void;
  openCreationModal: (open: boolean) => void;
}

interface AccountSearchFavoriteHistoricProps extends AccountSearchProps {
  favoriteLocations: DestinationInterface[];
  historicLocations: DestinationInterface[];
  onSelectDestination: (destination: DestinationInterface) => void;
}

export const AccountSearchFavoriteHistoric: FC<
  AccountSearchFavoriteHistoricProps
> = ({
  title,
  error,
  selected,
  disabled,
  placeholder,
  favoriteLocations,
  historicLocations,
  hideFavAndShip,
  onSelectClient,
  onSelectDestination,
  openCreationModal,
}) => {
  const [search, setSearch] = useState(' ');
  const [loader, setLoader] = useState(false);
  const [loaderFav, setLoaderFav] = useState(false);
  const [clients, setClients] = useState<AccountInterface[]>([]);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const [favoriteList, setFavoriteList] =
    useState<DestinationInterface[]>(favoriteLocations);
  const [historicList, setHistoricList] =
    useState<DestinationInterface[]>(historicLocations);

  const clientId = useAppSelector((state) => state.user.client?.id);
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );

  const getFavAndShip = useCallback(async (criteria: string) => {
    setLoaderFav(true);

    if (!clientId) {
      setFavoriteList([]);
      setHistoricList([]);
      setLoaderFav(false);
      return;
    }

    if (hideFavAndShip) {
      setFavoriteList([]);
      setHistoricList([]);
      setLoaderFav(false);
      return;
    }

    const response = await getFavoriteAndHistoricalLocations(
      clientId,
      identificationTypes,
      businessUnits,
      criteria
    );
    if (!response.didError && response.model) {
      setFavoriteList(response.model.favoriteDestinations);
      setHistoricList(response.model.historicalDestinations);
    } else {
      setFavoriteList([]);
      setHistoricList([]);
    }

    setLoaderFav(false);
  }, []);

  const getClientsData = useCallback(async (criteria: string) => {
    setLoader(true);

    if (criteria === '') {
      setClients([]);
      setLoader(false);
      return;
    }

    const clients = await getFilteredAccounts(criteria);

    if (!!clients) {
      setClients(clients);
    }
    setLoader(false);
  }, []);

  useEffect(() => {
    setHistoricList(historicLocations);
  }, [historicLocations]);

  useEffect(() => {
    setFavoriteList(favoriteLocations);
  }, [favoriteLocations]);

  // Handle search by debouncing the event
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearch(inputValue);
  };

  // Trigger search on enter key
  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getClientsData(search);
      getFavAndShip(search);
    }
  };

  useEffect(() => {
    setSearch(formatName(selected?.accountFullName ?? ''));
  }, [selected]);

  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    if (search.length > 0) {
      const newTimerId = setTimeout(() => {
        getClientsData(search);
        getFavAndShip(search);
      }, 1000);

      setTimerId(newTimerId);
    } else {
      getClientsData('');
      getFavAndShip('');
    }
  }, [search]);

  return (
    <div className="flex flex-1 flex-col">
      <div className="relative w-full">
        <FormGroupSearch<Partial<DestinationInterface>>
          clickeable
          value={search}
          id={`search-favorite-historic-account-${title}`}
          label={title}
          error={error}
          disabled={disabled}
          placeholder={placeholder}
          optionGroups={[
            {
              loader: loaderFav,
              title: 'Favoritos',
              options: favoriteList.slice(0, 5),
            },
            {
              loader: loaderFav,
              title: 'Recientes',
              options: historicList.slice(0, 5),
            },
            {
              loader,
              title: 'Clientes',
              options: clients.map((account) => ({
                account,
              })),
            },
          ]}
          onChange={handleChange}
          onClick={() => {
            getClientsData(search);
            getFavAndShip(search);
          }}
          onSelectOption={(option) => {
            if (!option) return;

            if (!!option.account && !option.location) {
              onSelectClient(option.account);
              setSearch(option.account.accountFullName);
            } else if (!!option.account && !!option.location) {
              setSearch(option.account.accountFullName);
              onSelectDestination(option as DestinationInterface);
            }
          }}
          onChangeFocus={(focus) => {
            if (!focus) setSearch(formatName(selected?.accountFullName ?? ''));
          }}
          RenderOption={({ option }) => <LocationOption {...option} />}
          LastOption={() => (
            <AddClient onClick={() => openCreationModal(true)} />
          )}
          onKeyDown={handleKeyDown}
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export const filterDestination = (
  search: string,
  client?: AccountInterface,
  location?: LocationInterface
) => {
  const criteria = search.toLowerCase();

  return (
    location?.name?.toLowerCase().includes(criteria) ||
    location?.address?.toLowerCase().includes(criteria) ||
    client?.accountFullName?.toLowerCase().includes(criteria) ||
    ((client?.abbreviationName ?? '') + client?.identificationNumber)
      .toLowerCase()
      .includes(criteria) ||
    (!!client?.listAccountPhone &&
      client?.listAccountPhone.some((phone) =>
        phone.phoneNumber?.toLowerCase().includes(criteria)
      ))
  );
};

const LocationOption: FC<Partial<DestinationInterface>> = ({
  account,
  location,
}) => {
  return (
    <div className="flex items-center">
      <div className="flex flex-col w-full">
        <p className="text-sm font-semibold leading-5 truncate">
          {account?.accountFullName}
        </p>
        {!account?.listAccountPhone ||
        account?.listAccountPhone.length === 0 ||
        (account?.abbreviationName !== 'V-' &&
          account?.abbreviationName !== 'E-') ? (
          <p className="text-sm leading-5">
            {account?.abbreviationName}
            {account?.identificationNumber}
          </p>
        ) : (
          <p className="text-sm leading-5">
            {!!account?.listAccountPhone &&
              account?.listAccountPhone.length > 0 &&
              account?.listAccountPhone[0].phoneNumber}
          </p>
        )}
        <p className="text-sm leading-5">{location?.name}</p>
        <p className="text-xs leading-5 truncate">
          {location?.address}{' '}
          {!!location?.postalCode
            ? `(código postal: ${location?.postalCode})`
            : ''}
        </p>
      </div>
    </div>
  );
};

const AddClient: FC<{ onClick: React.MouseEventHandler<HTMLDivElement> }> = ({
  onClick,
}) => {
  return (
    <div className="flex flex-1 py-3 items-center opacity-75" onClick={onClick}>
      <UserPlusIcon className="h-5 w-5 mr-2 shrink-0" />

      <p className="text-sm truncate">Crear nuevo</p>
    </div>
  );
};
